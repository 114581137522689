import React from 'react';
import image1 from '../images/hero1.jpg'; // Replace with your actual image paths
import image2 from '../images/hero2.jpg';
import image3 from '../images/hero3.jpg';
import image4 from '../images/hero1.jpg';

function Competencies() {
  return (
    <section className="py-12 bg-gray-100" id="JegTilbyder">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold text-center mb-8">Jeg tilbyder</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Competence 1 */}
          <div className="relative group">
            <img src={image1} alt="Competence 1" className="w-full h-64 object-cover rounded-lg shadow-md" />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex justify-center items-center">
              <p className="text-white text-lg font-bold">Kompetence 1</p>
            </div>
          </div>
          {/* Competence 2 */}
          <div className="relative group">
            <img src={image2} alt="Competence 2" className="w-full h-64 object-cover rounded-lg shadow-md" />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex justify-center items-center">
              <p className="text-white text-lg font-bold">Kompetence 2</p>
            </div>
          </div>
          {/* Competence 3 */}
          <div className="relative group">
            <img src={image3} alt="Competence 3" className="w-full h-64 object-cover rounded-lg shadow-md" />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex justify-center items-center">
              <p className="text-white text-lg font-bold">Kompetence 3</p>
            </div>
          </div>
          {/* Competence 4 */}
          <div className="relative group">
            <img src={image4} alt="Competence 4" className="w-full h-64 object-cover rounded-lg shadow-md" />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex justify-center items-center">
              <p className="text-white text-lg font-bold">Kompetence 4</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Competencies;
