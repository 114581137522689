import React from 'react'
import navbar_logo from '../images/Firma_Logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF} from '@fortawesome/free-brands-svg-icons';

function Navbar() {
    return (
        <nav className="sticky top-0 <-50 backdrop-blur-lg border-b border-neutral-700/80">
            <div className="p-2 relative text-sm bg-red-600">
                <div className="flex justify-center">
                    <ul className=' sm:flex lg:flex ml-14 space-x-2'>
                        <li className='text-white'><a href="tel:52384404">40 80 06 89</a></li>
                        <li className='text-white'>|</li>
                        <li className='text-white'><a href="mailto:jesper.wissing@gmail.com">jesper.wissing@gmail.com</a></li>
                        <li className='text-white'><a href="https://www.facebook.com/profile.php?id=100014630974373"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                    </ul>
                </div>
            </div>
            <div className="container px-4 mx-auto relative text-sm">
                <div className="flex justify-center items-center">
                    <div className="flex items-center flex-shrink-0">
                        <a href="/"><img className="h-40 w-40 mr-10" src={navbar_logo} alt="Logo" /></a>
                    </div>
                    <ul className='hidden lg:flex ml-14 space-x-12'>
                        <li className='text-red-600'><a href="/#Hjem">Hjem</a></li>
                        <li ><a href="/#JegTilbyder">Jeg Tilbyder</a></li>
                        <li ><a href="/#Kontakt">Kontakt</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
