import React from 'react'
import Navbar from './components/Navbar'
import HeroSection from './components/HeroSection'
import Competencies from './components/Competencies'
import Contact from './components/Contact'
import Footer from './components/Footer'

export default function App() {
  return (
    <>
    <Navbar />
    <HeroSection />
    <Competencies />
    <Contact />
    <Footer />
    </>
  )
}
