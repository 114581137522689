import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Carousel CSS
import image1 from '../images/hero1.jpg';
import image2 from '../images/hero2.jpg';
import image3 from '../images/hero3.jpg';

function HeroSection() {
  return (
    <section className="relative h-1/4 bg-gray-800" id="Hjem">
      {/* Image Carousel */}
      <Carousel 
        autoPlay 
        infiniteLoop 
        showThumbs={false} 
        showStatus={false} 
        showArrows={false} 
        interval={3000}
        className="h-full"
      >
        <div>
          <img src={image1} alt="First slide" className="object-cover h-screen w-full" />
        </div>
        <div>
          <img src={image2} alt="Second slide" className="object-cover h-screen w-full" />
        </div>
        <div>
          <img src={image3} alt="Third slide" className="object-cover h-screen w-full" />
        </div>
      </Carousel>

      {/* Overlay Box */}
      <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
        <div className="text-center p-8 bg-white bg-opacity-80 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-4 text-gray-800">Kontakt mig</h2>
          <p className="mb-6 text-gray-700">Kontakt mig og få et uforpligtende tilbud på din opgave</p>
          <a href="/#Kontakt"><button className="px-6 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700">
            Kontakt
          </button></a>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
