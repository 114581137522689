import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF} from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    return (
        <footer className="bg-gray-800 text-white py-6 mt-8">
            <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
                {/* Left Section: Company Info */}
                <div className="mb-4 md:mb-0 text-center md:text-left">
                    <h3 className="text-lg font-semibold">Murermester Jesper Wissing Aps</h3>
                    <p className="text-sm">
                        CVR: 30594894
                    </p>
                </div>

                 {/* Center Section: Contact Information */}
                 <div className="mb-4 md:mb-0">
                    <ul className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-6 text-sm">
                        <li className="flex items-center">
                            <FontAwesomeIcon icon={faPhone} className="mr-2" />
                            <a href="tel:52384404" className="hover:text-red-600">40 80 06 89</a>
                        </li>
                        <li className="flex items-center">
                            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                            <a href="mailto:jesper.wissing@gmail.com" className="hover:text-red-600">jesper.wissing@gmail.com</a>
                        </li>
                        <li className="flex items-center">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                            <a href="https://goo.gl/maps/your-map-link" className="hover:text-red-600" target="_blank" rel="noopener noreferrer">
                                Juelsmindevej 5, 9560 Hadsund
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Right Section: Social Media Links */}
                <div className="flex space-x-4">
                    <a href="https://www.facebook.com/profile.php?id=100014630974373" target="_blank" rel="noopener noreferrer" className="hover:text-red-600">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
